export const demo = {
  title: 'Getting started with Connect',
  description: [
    'Connect is the component that your customers will use to link their utility provider accounts to your application.',
    'Connect gets a customer’s consent to access their utility accounts as asks them to provide their utility login credentials to create a link to their accounts. After the link is made, your systems can use all of the features of the Arcadia Platform.',
  ],
  launchConnectButton: 'Connect a utility account',
  loading: 'Loading...',
  errors: {
    authorizationExpired:
      'Your connect token has expired. Please reconnect your utility account.',
    connectFailure:
      'Something went wrong. Please reload the page or try again later.',
    rejectedCredentials:
      'Your credentials were rejected and we are unable to load your utility data. Please try again, or enter some new utility credentials',
    noUtilityAccounts: 'No utility accounts found',
    authentication: 'Authentication error',
  },
  errorModal: {
    title: 'Something went wrong!',
    cta: 'Connect another account',
  },
  nav: {
    links: {
      plug: 'Plug',
      spark: 'Spark',
    },
  },
  results: {
    connectedText: 'Connected to %{utility} account',
    description:
      'The examples below are for demonstration purposes only. You can view sample graphics and tables and inspect the API responses that provide the underlying data.',
    fallbackUtilityName: 'utility',
    resetCredentials: 'Restart demo',
    plug: {
      title: 'Plug',
      description:
        "The Plug API can be used to retrieve information about your customer's utility accounts including account details and statements (both current and historic).",
      accountsSection: {
        title: 'Account',
        helperText:
          'The selected account will determine the statement and interval data shown below.',
        accountSelect: {
          label: 'Select account',
        },
      },
      tables: {
        utilityCredentials: {
          title: 'Credentials',
          helperText:
            'Metadata about your customer’s connection with a utility provider, including the verification status of that connection.',
        },
        utilityAccounts: {
          title: 'Details',
          helperText:
            'Information on each of your customer’s accounts at the utility provider. One utility connection can have multiple accounts.',
        },
        utilityStatements: {
          title: 'Statements',
          helperText:
            'Current and historic statement data for a given customer’s utility account.',
          accountSelect: {
            label: 'Select account',
          },
          columns: {
            statementDate: 'Date',
            servicePeriod: 'Service Period',
            dueDate: 'Due Date',
            kwh: 'kWh',
            total: 'Total',
          },
        },
        intervalData: {
          title: 'Interval Data',
          loading:
            'Your interval data is loading, this could take a few minutes.',
          helperText:
            'Current and historic interval data for the selected account. Interval data is updated by Arcadia every 30 days.',
          unavailableWarning:
            'Interval data is not available for this utility.',
          unavailableWarningRealAccount:
            'Interval data in the demo is only available for test accounts',
          noIntervalsError:
            'Failed to retrieve interval data for the last week.',
          chart: {
            title: 'Daily',
            yAxis: 'kWh',
            previousButtonLabel: 'Previous day',
            nextButtonLabel: 'Next day',
          },
        },
      },
    },
    apiExplorer: {
      requestHeader: 'Request',
      responseHeader: 'Response',
    },
    apiExplorerButton: {
      labelShowingAPI: {
        table: 'Table View',
        graph: 'Graph View',
      },
      labelShowingTable: 'Inspect API',
    },
  },
};
