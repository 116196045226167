import { useCallback, useMemo, useState } from 'react';
import { Page } from 'styles';
import { overview } from 'config/paths';
import { copyFor } from 'config/copy';
import { parseErrorMessage } from 'config/errors';
import { CenteredContentNarrow, Title } from 'styles/single-content.style';
import { useMutation } from '@apollo/client';
import { Login as LoginForm, LoginSubmitParams } from 'components/forms/login';
import { SsoLogin } from 'components/sso-login';
import { NavBarUnauthed } from 'components/nav-bar';
import { authenticate, setPathForSSORedirect } from 'config/auth';
import { useQueryParams } from 'hooks/use-query-params.hook';
import { CHECK_SSO_ENROLLMENT, LOG_IN_TENANT_USER } from './login.api';

const getCopy = copyFor('login');

export const Login = (): JSX.Element => {
  const queryParams = useQueryParams();

  const [pending, setPending] = useState<boolean>(false);
  const [tenantName, setTenantName] = useState<string>('');
  const [tenantLogoUrl, setTenantLogoUrl] = useState<string>('');
  const [ssoLink, setSsoLink] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>(() => {
    const isExpired = !!queryParams.get('expired');
    if (isExpired) return getCopy('expired');
  });

  const [logInTenantUser] = useMutation<
    LogInTenantUserMutation,
    LogInTenantUserMutationVariables
  >(LOG_IN_TENANT_USER);

  const [checkSsoEnrollment] = useMutation<
    CheckSsoEnrollmentMutation,
    CheckSsoEnrollmentMutationVariables
  >(CHECK_SSO_ENROLLMENT);

  const handleSubmit = useCallback(
    async ({ email, password }: LoginSubmitParams) => {
      setPending(true);
      setErrorMessage(undefined);

      try {
        const { data } = await logInTenantUser({
          variables: { input: { email, password } },
        });
        // Note: There should always be data here - the function would throw
        // if something went wrong here
        if (data) {
          const { token, expiresAt } = data.logInTenantUser.accessToken;
          const next = queryParams.get('next');
          authenticate(token, expiresAt, {
            resourceOwner: data.logInTenantUser.resourceOwner,
            navigateTo: next || overview,
          });
        }
      } catch (e) {
        setPending(false);
        const errorMessage = parseErrorMessage(e as Error);
        setErrorMessage(errorMessage);
      }
    },
    [logInTenantUser, queryParams]
  );

  const checkSsoLogin = useCallback(
    async (email: string) => {
      try {
        const { data } = await checkSsoEnrollment({
          variables: { input: { email } },
        });
        if (data) {
          const { ssoLink, tenantName, tenantLogoUrl } =
            data.checkSsoEnrollment;
          if (ssoLink) {
            if (tenantName) setTenantName(tenantName);
            if (tenantLogoUrl) setTenantLogoUrl(tenantLogoUrl);
            const next = queryParams.get('next');
            setPathForSSORedirect(next);
            setSsoLink(ssoLink);
          }
        }
      } catch (e) {
        setTenantName('');
        setTenantLogoUrl('');
        setSsoLink('');
      }
    },
    [checkSsoEnrollment, queryParams]
  );

  const standardLogin = useMemo(
    () => (
      <CenteredContentNarrow data-testid="standard-login">
        <Title>{getCopy('title')}</Title>
        <LoginForm
          onSubmit={handleSubmit}
          pending={pending}
          errorMessage={errorMessage}
          checkSsoLogin={checkSsoLogin}
        />
      </CenteredContentNarrow>
    ),
    [handleSubmit, pending, errorMessage, checkSsoLogin]
  );

  const ssoLogin = useMemo(
    () => (
      <CenteredContentNarrow data-testid="sso-login">
        <SsoLogin
          ssoLink={ssoLink}
          tenantName={tenantName}
          tenantLogoUrl={tenantLogoUrl}
        />
      </CenteredContentNarrow>
    ),
    [ssoLink, tenantName, tenantLogoUrl]
  );

  return (
    <Page>
      <NavBarUnauthed />
      {ssoLink ? ssoLogin : standardLogin}
    </Page>
  );
};
