export const support = 'mailto:arcsupport@arcadia.com';
export const contactForm = 'https://www.arcadia.com/contact';
export const apiDocs =
  'https://docs.arcadia.com/v2022-10-13/reference/overview';
export const productGuides = 'https://docs.arcadia.com/docs';
export const apiKeysDocumentation =
  'https://docs.arcadia.com/v2022-10-13/reference/authentication';

export const getLegalLink = (contractVersionId: number) =>
  `https://legal.arcadia.com?id=${contractVersionId}`;

export const customizationDocumentation =
  'https://docs.arcadia.com/v2022-10-13/reference/overview';

export const webhooksDocumentation =
  'https://docs.arcadia.com/v2022-10-13/reference/webhooks';

export const connectIframeUrl = (tenantName: string, logoUrl: string) =>
  `https://connect.arcadia.com/storybook/iframe.html?id=external-use-tenant-preview--default&tenant=${encodeURIComponent(
    tenantName
  )}&tenantLogo=${btoa(logoUrl)}&viewMode=story`;
