import { useMutation } from '@apollo/client';
import { IconButton, Alert } from '@arcadiapower/shrike';
import { tenantLogoSvgPath } from 'assets/icons/paths';
import { useState } from 'react';
import { getErrorCopy } from 'config/copy';
import { TableSectionTitle } from 'styles/table.style';
import styled from 'styled-components';
import {
  ValueWrapper,
  Value,
  InputGroup,
  CustomIcon,
  LogoFileWrapper,
} from './read-only-logo-upload.styles';
import { DELETE_TENANT_LOGO } from './read-only-logo-upload.api';

export type Props = {
  className?: string;
  label: string;
  value?: string;
};

export const ButtonContainer = styled.div`
  max-height: 32px;
  min-height: 32px;
`;
export const ReadOnlyLogoUpload = ({
  label,
  value,
  className,
}: Props): JSX.Element => {
  const [deleteTenantLogo] = useMutation<
    DeleteTenantLogoMutation,
    DeleteTenantLogoMutationVariables
  >(DELETE_TENANT_LOGO);

  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const deleteLogo = async () => {
    setLoading(true);
    try {
      await deleteTenantLogo({ variables: { input: {} } });
      setLoading(false);
    } catch (error) {
      setError(getErrorCopy('generic'));
      setLoading(false);
    }
  };

  return (
    <InputGroup className={className}>
      <TableSectionTitle>{label}</TableSectionTitle>
      <LogoFileWrapper>
        <ValueWrapper>
          <CustomIcon
            path={tenantLogoSvgPath}
            color="primary"
            aria-label="logo"
          />
          <Value>{value}</Value>
          <ButtonContainer>
            <IconButton
              icon="UIDelete"
              disabled={loading}
              data-testid="delete-button"
              color="error"
              aria-label="delete"
              onClick={deleteLogo}
            />
          </ButtonContainer>
          {error && <Alert margin="0 0 0 1rem">{error}</Alert>}
        </ValueWrapper>
      </LogoFileWrapper>
    </InputGroup>
  );
};
