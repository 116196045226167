import styled from 'styled-components';
import arcLogoFull from 'assets/arc-logo-full.svg';
import arcLogoFullInverted from 'assets/arc-logo-full-inverted.svg';
import { Text } from '@arcadiapower/shrike';
import { NavBarLink } from 'components/nav-bar-item/nav-bar-item.style';

export const NavBarContainer = styled.div<{
  withBorder: boolean;
  inverted?: boolean;
}>`
  width: 100%;
  height: 72px;

  // Prevent the component from "jumping up" when clicked
  border-bottom: 1px solid transparent;

  // TODO: Come up with proper mobile strategy
  ${props => props.theme.media.navBarMobile} {
    height: auto;
  }

  ${props =>
    props.inverted &&
    `background-color: ${props.theme.colors.background.primaryInverse}`}

  ${props =>
    props.withBorder &&
    `
    && {
      border-bottom: 1px solid ${props.theme.colors.background.tertiary};
    }
  `}
`;

export const NavBarDemoContainer = styled(NavBarContainer).attrs({
  inverted: true,
  withBorder: false,
})``;

export const NavBarContent = styled.div`
  margin: 0 auto;
  display: flex;
  max-width: 1440px;
  height: 100%;
  padding: 0px 32px;
  width: 100%;
  justify-content: space-between;

  // TODO: Come up with proper mobile strategy
  ${props => props.theme.media.navBarMobile} {
    flex-direction: column;
    padding-bottom: 16px;
    padding-top: 16px;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  padding: 16px 0;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;

  ${props => props.theme.media.phone} {
    padding: 0px, 0px, 8px, 0px;
  }
`;

export const VerticalSeperator = styled.div`
  height: 35px;
  width: 1px;
  background-color: ${props => props.theme.colors.background.tertiary};
`;

export const NavBarTitleLogoContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;

type LogoProps = {
  inverted?: boolean;
};

export const Logo = styled.img.attrs<LogoProps>(props => ({
  alt: 'Developer Platform Logo',
  src: props.inverted ? arcLogoFullInverted : arcLogoFull,
}))<LogoProps>`
  height: 24px;
  width: auto;
`;

export const BrandText = styled(Text)`
  margin: auto 0px;
`;

export const NavGroup = styled.div`
  height: 100%;
  display: flex;

  // TODO: Come up with proper mobile strategy
  ${props => props.theme.media.navBarMobile} {
    flex-direction: column;
  }

  ${props => props.theme.media.navBarFull} {
    ${NavBarLink} {
      &:not(:last-child) {
        margin-right: 48px;
      }
    }
  }
`;
