import { useMutation } from '@apollo/client';
import { copyFor, getErrorCopy } from 'config/copy';
import { useRef, useState, DragEvent, ChangeEvent } from 'react';
import { Loading, Button, Alert } from '@arcadiapower/shrike';
import { notEmpty } from 'utils/functions';
import styled from 'styled-components';
import upload from 'assets/upload.svg';
import { UPDATE_TENANT_LOGO } from './image-upload.api';
import { Wrapper, Header, HiddenInput, InfoText } from './image-upload.style';
import { convertAndValidateFileArray, FileUpload } from './image-upload.utils';

const getCopy = copyFor('forms.logoUpload');

enum UiState {
  AVAILABLE,
  LOADING,
  HOVERING,
}

const UploadIcon = styled.img.attrs({
  src: upload,
})`
  height: 16px;
  width: 16px;
`;

export const ImageUpload = (): JSX.Element => {
  const [uiState, setUiState] = useState<UiState>(UiState.AVAILABLE);
  const [error, setError] = useState<Error>();
  const inputRef = useRef<HTMLInputElement>(null);
  const [updateTenantLogo] = useMutation<
    UpdateTenantLogoMutation,
    UpdateTenantLogoMutationVariables
  >(UPDATE_TENANT_LOGO);

  const startUpload = () => {
    inputRef?.current?.click();
  };

  const dropHandler = async (event: DragEvent) => {
    event.preventDefault();
    const imageType = /image.*/;

    const files = Array.from(event.dataTransfer.items)
      // don't try to process non-images
      .filter(data => data.kind === 'file' && data.type.match(imageType))
      .map(file => file.getAsFile())
      .filter(notEmpty);

    const fileUploads = await convertAndValidateFileArray(files);
    await uploadLogo(fileUploads[0]);
  };

  const handleFiles = async (event: ChangeEvent) => {
    event.preventDefault();
    const fileList = inputRef?.current?.files;
    const fileArray: File[] = Array.from(fileList as ArrayLike<File>);
    const files = await convertAndValidateFileArray(fileArray);
    await uploadLogo(files[0]);
  };

  const uploadLogo = async (file?: FileUpload) => {
    setUiState(UiState.LOADING);
    if (!file) {
      setError(new Error(getErrorCopy('validImage')));
      setUiState(UiState.AVAILABLE);
      return;
    }
    if (file.size > 1048576) {
      setError(new Error(getErrorCopy('fileSize')));
      setUiState(UiState.AVAILABLE);
      return;
    }
    try {
      await updateTenantLogo({
        variables: {
          input: {
            base64Logo: file.base64,
          },
        },
      });
    } catch (error) {
      setUiState(UiState.AVAILABLE);
      setError(new Error(getErrorCopy('generic')));
      // Clear value for other errors so onChange still fires
      if (inputRef?.current?.value) {
        inputRef.current.value = '';
      }
    }
  };

  const availableState = (): JSX.Element => (
    <>
      <UploadIcon />
      <Header margin={{ top: '16px' }}>{getCopy('available.header')}</Header>
      <InfoText margin={{ top: '8px' }} color="secondary">
        {getCopy('available.helperText')}
      </InfoText>
      <Button
        margin={{ top: '24px' }}
        data-testid="upload-button"
        onClick={startUpload}
      >
        {getCopy('available.button')}
      </Button>
    </>
  );

  const loadingState = (): JSX.Element => (
    <>
      <Loading />
      <Header>{getCopy('loading.header')}</Header>
      <InfoText color="secondary">{getCopy('loading.helperText')}</InfoText>
    </>
  );

  const hoveringState = (): JSX.Element => (
    <>
      <UploadIcon />
      <Header>{getCopy('hovering.header')}</Header>
      <InfoText color="secondary">{getCopy('hovering.helperText')}</InfoText>
    </>
  );

  const content = (): JSX.Element => {
    switch (uiState) {
      case UiState.LOADING:
        return loadingState();
      case UiState.HOVERING:
        return hoveringState();
      case UiState.AVAILABLE:
      default:
        return availableState();
    }
  };

  const handleDragover = (event: DragEvent) => {
    event.preventDefault();
    setUiState(UiState.HOVERING);
  };

  const handleOnDragLeave = (event: DragEvent) => {
    event.preventDefault();
    setUiState(UiState.AVAILABLE);
  };

  return (
    <Wrapper
      onDrop={dropHandler}
      onDragOver={handleDragover}
      onDragLeave={handleOnDragLeave}
      data-testid="upload-container"
      hovering={uiState === UiState.HOVERING}
    >
      <HiddenInput
        aria-hidden
        multiple={false}
        ref={inputRef}
        type="file"
        data-testid="hidden-file-input"
        onChange={handleFiles}
        accept="image/png, image/jpeg, image/svg+xml"
      />
      {content()}
      {error && <Alert>{error.message}</Alert>}
    </Wrapper>
  );
};
