export const customerOverview = {
  missingCustomerName: 'Unknown name',
  missingData: 'None available',
  customerInformation: {
    clientUserIdLabel: 'client_user_id:',
    clientUserIdTooltip:
      'This ID represents a unique user and is supplied by you when this user is first created on the Arcadia platform—ideally it should match your system’s unique identifier for the same user. This ID is required to initialize a session of Connect for a user that you’d like to create a new utility connection for. You can also retrieve user level information with this ID.',
  },
  utilityCredential: {
    title: 'Utility Credential',
    utilityCredentialIdLabel: 'utility_credential_id:',
    utilityCredentialIdTooltip:
      'This ID represents a user’s login credentials for a specific utility. This ID is required to initialize Connect in ‘update mode’, which will allow a user to repair a broken connection to the relevant utility. You can also retrieve credential level information with this ID.',
    username: 'Username',
    utility: 'Utility',
    utilityId: 'Utility ID',
    credentialsStatus: 'Credentials Status',
  },
  utilityAccount: {
    title: 'Account Information',
    utilityAccountIdLabel: 'utility_account_id:',
    utilityAccountIdTooltip:
      'This ID represents a specific account that a user has with a utility provider. A user may have multiple active accounts at any number of utility providers. You can retrieve account level information with this ID.',
    accountNumber: 'Utility Account',
    customerName: 'Customer',
    serviceAddress: 'Service Address',
    phoneNumber: 'Phone number',
    serviceType: 'Service Type',
    accountStatus: 'Account Status',
  },
  credentialInformationModal: {
    buttonLabel: 'Show more information',
    modalContent: {
      rejected: {
        title: 'Credential Status',
        contentTitle: 'Rejected',
        description:
          'This user’s credentials for **%{utility}** was determined to be incorrect. In order to repair this connection, you should initialize a session of Connect for this user in update-mode, which can be done by providing their `utility_credential_id` , which is `%{utilityCredentialId}` , as the parameter to retrieve a [Connect Token](https://docs.arcadia.com/v2022-10-13/docs/connect-unlock-your-customers-utility-provider-data). Use this Connect Token to initialize a new session of Connect for this user to repair their connection to **%{utility}**.',
      },
      pending: {
        title: 'Credential Status',
        contentTitle: 'Pending',
        description:
          'This user’s username and password are pending verification with **%{utility}**. If this state persists, you can make another attempt by initializing a session of Connect for this user in update-mode. This can be done by providing their `utility_credential_id` , which is `%{utilityCredentialId}` , as the parameter to retrieve a [Connect Token](https://docs.arcadia.com/v2022-10-13/docs/connect-unlock-your-customers-utility-provider-data). Use this Connect Token to initialize a new session of Connect for this user.',
      },
      error: {
        title: 'Credential Status',
        contentTitle: 'Error',
        description:
          'There was an error making a connection to **%{utility}** for this user’s credentials. Reach out to Arcadia support for assistance [arcsupport@arcadia.com](mailto:arcsupport@arcadia.com)',
      },
    },
  },
};
