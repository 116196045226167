export const forms = {
  agreeToTerms: {
    preContractText: 'By continuing you’re agreeing to Arcadia’s Platform ',
    regularJoiner: ', ',
    lastJoiner: ' and ',
    postContractText: '.',
  },
  login: {
    cta: 'Sign in',
    errors: {
      invalidCredentials: 'Your credentials are incorrect',
      generic: 'Something went wrong. Please try again.',
    },
    inputs: {
      email: {
        name: 'email',
        label: 'Email',
        placeholder: 'captain@pla.net',
      },
      password: {
        name: 'password',
        label: 'Password',
        placeholder: 'Enter your password',
      },
    },
    signUpCta: {
      text: 'Still need to make an account? ',
      link: 'Sign up here.',
    },
    forgotPassword: {
      link: 'Reset password',
    },
  },
  setUpAccount: {
    inputs: {
      password: {
        label: 'Password',
        placeholder: '8+ characters',
        helperText: 'Must contain at least 8 characters and include a number',
      },
      firstName: {
        label: 'First name',
        placeholder: 'Captain',
      },
      lastName: {
        label: 'Last name',
        placeholder: 'Planet',
      },
    },
    cta: 'Set new password',
  },
  signup: {
    cta: 'Create account',
    signInCta: {
      text: 'Already have an Arcadia account?',
      link: 'Sign in.',
    },
    inputs: {
      email: {
        label: 'Email',
        placeholder: 'e.g. capt.planet@theplaneteers.com',
      },
      password: {
        label: 'Password',
      },
      firstName: {
        label: 'First name',
        placeholder: 'e.g. Dave',
      },
      lastName: {
        label: 'Last name',
        placeholder: 'e.g. Coburn',
      },
      company: {
        label: 'Organization',
        placeholder: 'e.g. Planeteering Group LLC',
        helperText: 'Name must be less than 50 characters',
      },
    },
  },
  accountDetails: {
    inputs: {
      email: {
        name: 'email',
        label: 'Email address',
      },
      password: {
        label: 'Password',
      },
      firstName: {
        name: 'firstName',
        label: 'First name',
      },
      lastName: {
        name: 'lastName',
        label: 'Last name',
      },
    },
    submit: 'Save',
    cancel: 'Cancel',
  },
  resetPassword: {
    cta: {
      normal: 'Send link',
      submitted: 'Okay',
    },
    secondaryCta: {
      text: "Didn't forget your password?",
      link: 'Sign in',
    },
    inputs: {
      email: {
        name: 'email',
        label: 'Email address',
        placeholder: 'captain@pla.net',
      },
    },
  },
  changePassword: {
    inputs: {
      password: {
        name: 'password',
        label: 'Password',
        helperText: 'Must contain at least 8 characters and include a number',
      },
    },
    submit: 'Reset password',
  },
  customization: {
    inputs: {
      name: {
        name: 'name',
        label: 'Company Name',
        helperText: 'Name must be less than 50 characters',
      },
      logoUrl: {
        name: 'logo',
        label: 'Logo',
      },
      utilityConnectPreview: {
        name: 'utilityConnectPreview',
        label: 'Your Connect preview:',
      },
    },
    submit: 'Save',
    cancel: 'Cancel',
  },
  logoUpload: {
    available: {
      header: 'Drag and drop your logo here',
      helperText:
        'Recommended format - JPG, PNG, SVG. Recommended size - 500 x 500 pixels.',
      button: 'Upload from computer',
    },
    loading: {
      header: 'Uploading your logo...',
      helperText:
        'Depending on the file size, this could take a couple of minutes.',
    },
    hovering: {
      header: 'Drop to upload',
      helperText:
        'Depending on the file size, this could take a couple of minutes.',
    },
  },
  customerSearch: {
    helperText: 'Search for customers using their account info',
    helperTextTooltip:
      'Searchable information includes utility accounts, service addresses, names, emails, and phone numbers',
    inputs: {
      search: {
        label: 'Customer Search',
      },
      customerType: {
        sandbox: {
          name: 'Sandbox customers',
          label: 'Sandbox customers',
        },
        live: {
          name: 'Live customers',
          label: 'Live customers',
        },
      },
    },
  },
  spark: {
    button: 'Submit',
    inputs: {
      energyAdded: {
        label: 'Energy added (kWh)',
        name: 'Energy added',
      },
      startTime: {
        label: 'Start time',
        name: 'Start time',
      },
      endTime: {
        label: 'Completion time',
        name: 'Completion time',
      },
    },
    dropdown: {
      label: 'Charge power (kW)',
      name: 'Charge power',
    },
    smartCharge: {
      name: 'Calculate Smart Charge schedule and cost savings',
      label: 'Calculate Smart Charge schedule and cost savings',
      helperTextLabel: 'Calculate Smart Charge helper text',
      helperText:
        'If you enable “Smart Charge calculation,” Spark will recommend adjustments to the charge schedule to minimize the total cost of charging, based on variations in the utility provider’s tariff for you customer.',
    },
    errors: {
      excessiveChargeTime:
        'The charge time exceeds 24 hours. Please select different values for energy added (kWh) and/or charge power (kW).',
      loadingStatements:
        'Something when wrong loading your statements. Please reset the demo and start over',
      generic: 'Something went wrong. Please try again.',
    },
  },
  addTeamMember: {
    inputs: {
      email: {
        name: 'Email',
        label: 'Email address',
      },
    },
    submit: 'Send invite',
    cancel: 'Cancel',
    errors: {
      generic:
        'There was a problem sending this invite, please try again later.',
    },
  },
};
